import * as yup from 'yup';
import {
  MAX_11_CHARS,
  MAX_255_CHARS,
  MIN_2_CHARS,
  MIN_5_DIGITS,
  POSTAL_CODE_REGEX,
  REQUIRED,
  SSN_MASKED_REGEX,
  SSN_VALIDATION_MESSAGE,
  EIN_REGEX,
  EIN_VALIDATION_MESSAGE,
} from '@/lib/validations';
import { onboardingConstants, onboardingDataValues } from '@/lib/constants';

const {
  W9_FULL_NAME,
  W9_BUSINESS_NAME,
  W9_TAX_CLASSIFICATION,
  W9_TAX_CLASSIFICATION_LLC,
  W9_TAX_CLASSIFICATION_OTHER,
  W9_STREET_ADDRESS,
  W9_CITY,
  W9_STATE,
  W9_ZIP,
  W9_SSN,
  W9_EIN,
} = onboardingConstants;

export const validationSchema = yup.object().shape({
  [W9_FULL_NAME]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [W9_BUSINESS_NAME]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED),
  [W9_TAX_CLASSIFICATION]: yup
    .string()
    .required(REQUIRED)
    .nullable(),
  [W9_TAX_CLASSIFICATION_LLC]: yup
    .string()
    .when(W9_TAX_CLASSIFICATION, {
      is: onboardingDataValues.W9_LLC_VALUE,
      then: yup.string()
        .checkWhiteSpacesOnly(REQUIRED)
        .required(REQUIRED)
        .min(2, MIN_2_CHARS)
        .max(255, MAX_255_CHARS),
      otherwise: yup.string().nullable(),
    }),
  [W9_TAX_CLASSIFICATION_OTHER]: yup
    .string()
    .when(W9_TAX_CLASSIFICATION, {
      is: onboardingDataValues.W9_OTHER_VALUE,
      then: yup.string()
        .required(REQUIRED)
        .min(2, MIN_2_CHARS)
        .max(255, MAX_255_CHARS),
      otherwise: yup.string().nullable(),
    }),
  [W9_STREET_ADDRESS]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [W9_CITY]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED)
    .min(2, MIN_2_CHARS)
    .max(255, MAX_255_CHARS),
  [W9_STATE]: yup
    .string()
    .checkWhiteSpacesOnly(REQUIRED)
    .required(REQUIRED),
  [W9_ZIP]: yup
    .string()
    .required(REQUIRED)
    .matches(POSTAL_CODE_REGEX, {
      excludeEmptyString: true,
      message: MIN_5_DIGITS,
    })
    .max(11, MAX_11_CHARS),
  [W9_SSN]: yup
    .string()
    .matches(SSN_MASKED_REGEX, {
      excludeEmptyString: true,
      message: SSN_VALIDATION_MESSAGE,
    })
    .required(REQUIRED),
  [W9_EIN]: yup
    .string()
    .matches(EIN_REGEX, {
      excludeEmptyString: true,
      message: EIN_VALIDATION_MESSAGE,
    })
    .when(W9_BUSINESS_NAME, {
      is: (value) => value && value.trim().length > 0,
      then: yup.string().required(REQUIRED),
      otherwise: yup.string().nullable(),
    }),
});
