export const REQUIRED = 'Required Field';
export const MIN_5_DIGITS = 'Min 5 digits';
export const SSN_4_DIGITS = 'SSN field must have exactly 4 digits';
export const MIN_1_NUM = 'Min 1 number is required';
export const MIN_1_ARRAY = 'Min 1 element is required';
export const MIN_2_CHARS = 'Min 2 characters are required';
export const MAX_11_CHARS = 'Max 11 characters are allowed';
export const MAX_20_CHARS = 'Max 20 characters are allowed';
export const MAX_50_CHARS = 'Max 50 characters are allowed';
export const MAX_100_CHARS = 'Max 100 characters are allowed';
export const MAX_255_CHARS = 'Max 255 characters are allowed';
export const SSN_VALIDATION_MESSAGE = 'SSN should be in XXX-XX-XXXX format and contains digits only';
export const EIN_VALIDATION_MESSAGE = 'EIN should be in XX-XXXXXXX format and contains digits only';
export const EMAIL_VALIDATION_MESSAGE = 'Invalid email format';
export const SSN_DIGITS_MESSAGE = 'This field should contain only digits';
export const YEAR_DIGITS_MESSAGE = 'Year should be in XXXX format and contains digits only';
export const MIN_ONE_ENTRY_LIST = 'At least one entity is required';
export const MIN_TWO_ENTRY_LIST = 'At least two entities are required';
export const FEET_VALIDATION_MESSAGE = 'Digits from 1 to 99 range are allowed';
export const INCHES_VALIDATION_MESSAGE = 'Digits from 1 to 12 range are allowed';
export const WEIGHT_VALIDATION_MESSAGE = 'Digits from 1 to 660 range are allowed';
export const REP_ACCOUNT_NUMBERS_MESSAGE = 'Digits greater than 0 are allowed';
export const PHONE_VALIDATION_MESSAGE = 'Phone should be in (XXX) XXX-XXXX format';
export const ARRIVAL_DATE_MIN_MESSAGE = 'Arrival date cannot be earlier than 3 days before your knocking start date';
export const ARRIVAL_DATE_MAX_MESSAGE = 'Arrival date cannot be later than the knocking start date';
export const KNOCKING_END_DATE_MIN_MESSAGE = 'Knocking end date cannot be before knocking start date';
export const ACTUAL_END_DATE_MIN_MESSAGE = 'Actual end date cannot be before actual start date';
export const RESIDENTIAL_END_DATE_MESSAGE = 'Residential history end date cannot be before Residential history start date';
export const EMPLOYMENT_END_DATE_MESSAGE = 'Employment history end date cannot be before Employment history start date';
export const INVALID_DATE_MESSAGE = 'Invalid date format';
export const WOTC_SURVEY_MESSAGE = 'You must complete a survey';
export const W9_MESSAGE = 'You must complete W-9';
export const I9_MESSAGE = 'You must complete I-9';
export const ERROR_500 = 'Something went wrong, please try again. If issue persists, contact system administrator.';
export const LEDGER_CURRENCY_MESSAGE = 'The amount should be in range of -999,999.99 and 999,999.99.';
export const EIN_MAX_VALIDATION_MESSAGE = 'The employer identification number (EIN) should be a nine-digit number';
export const PRONTO_USER_ID_VALIDATION_MESSAGE = 'Only digits are allowed';
